.Screenshots-container {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  padding: 20px;
  box-sizing: border-box;
}

.Screenshots-container h1 {
  margin-bottom: 20px;
}

.Screenshots-description {
  font-size: 1rem;
  margin-bottom: 30px;
  text-align: center;
}

.Screenshots-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  width: 100%;
  max-width: 1200px;
}

.Screenshot {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .Screenshots-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
}
