.about-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
}

.about-container h1 {
  margin-bottom: 20px;
}

.about-container h2 {
  color: #333;
  margin-top: 30px;
}

.about-container p {
  line-height: 1.6;
  margin-bottom: 15px;
}

.about-container ul {
  list-style-type: disc;
  margin-left: 20px;
}

.about-container li {
  margin-bottom: 10px;
}
