.customer-reviews {
  background-color: #f1f2f3;
  padding: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  color: #333;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}

.reviews-header {
  margin-bottom: 30px;
}

.reviews-title-container {
  width: 100%;
  margin-left: 30px;
  padding: 0 15px;
  box-sizing: border-box;
}

.reviews-title {
  font-size: 1.5rem;
  margin: 0 0 10px 0;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.app-store-icon {
  width: 30px;
  height: 30px;
  margin-left: 10px;
}

.app-store-rating {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.rating-summary {
  display: flex;
  align-items: baseline;
  margin-right: 20px;
}

.average-rating {
  font-size: 2.5rem;
  font-weight: bold;
  line-height: 1;
  margin-right: 5px;
}

.out-of {
  font-size: 0.9rem;
  color: #666;
}

.star-rating {
  display: flex;
  margin-right: 20px;
}

.review-count {
  font-size: 0.9rem;
  color: #666;
}

.reviews-carousel {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  width: 100%;
  box-sizing: border-box;
}

.reviews-container {
  display: grid;
  gap: 20px;
  width: 100%;
  transition: none;
}

.review-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 15px;
  width: 100%;
  min-width: 0;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.review-header {
  margin-bottom: 10px;
}

.review-meta {
  display: flex;
  justify-content: space-between;
  font-size: 0.8rem;
  color: #666;
  margin-top: 5px;
}

.review-title {
  font-size: 1rem;
  margin: 10px 0;
}

.review-text {
  font-size: 0.9rem;
  line-height: 1.4;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  flex-grow: 1;
}

.carousel-button {
  background: none;
  border: none;
  cursor: pointer;
  color: #007aff;
  padding: 10px;
  display: flex;
  align-items: center;
}

.carousel-button:disabled {
  color: #ccc;
  cursor: not-allowed;
}

.review-button {
  display: block;
  color: #007aff;
  text-decoration: none;
  font-weight: bold;
  font-size: 0.9rem;
  text-align: center;
  margin: 20px auto 0;
  width: fit-content;
}

.view-button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.view-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #666;
  text-decoration: none;
  font-size: 0.8rem;
}

.view-button:hover {
  text-decoration: underline;
}

.country-flag {
  width: 16px;
  height: 16px;
  margin-left: 5px;
}

@media (min-width: 1200px) {
  .reviews-container {
    grid-template-columns: repeat(5, 1fr);
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .reviews-container {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 767px) {
  .reviews-container {
    grid-template-columns: 1fr;
  }

  .customer-reviews {
    padding: 15px;
  }

  .reviews-carousel {
    margin-left: -15px;
    margin-right: -15px;
  }

  .reviews-container {
    padding: 0 15px;
  }

  .carousel-button {
    padding: 5px;
  }
}
