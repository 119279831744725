.navbar {
  display: flex;
  align-items: center;
  padding: 0.5rem 2rem;
  background-color: #282c34;
  color: white;
  height: 60px;
}

.nav-logo {
  display: flex;
  align-items: center;
  margin-right: 2rem;
}

.nav-logo img {
  height: 40px;
  width: auto;
}

.nav-items {
  display: flex;
  gap: 3rem;
  align-items: center;
}

.nav-items a {
  color: white;
  text-decoration: none;
  font-size: rem;
  gap: 2rem; /* Increased from 1rem to 2rem for more spacing */
  font-weight: bold; /* Make the text bold */
  transition: color 0.3s ease; /* Smooth transition for color change */
}

.nav-items a:hover {
  color: #2d5be5; /* Change to blue on hover */
}

.nav-toggle {
  display: none;
}

@media (max-width: 768px) {
  .navbar {
    justify-content: space-between;
  }

  .nav-items {
    display: none;
    gap: 1rem; /* Reduce gap for mobile view */
  }

  .nav-items.open {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 60px;
    left: 0;
    width: 100%;
    background-color: #282c34;
    padding: 1rem;
  }

  .nav-toggle {
    display: block;
    cursor: pointer;
  }

  .bar {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 5px 0;
  }
}
